.top-list-item-rating {
  color: #A3B2FF;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  padding: 3px 0;
  margin-right: 18px;
}
.top-list-item-link {
  width: 100%;
  font-size: 14px;
  color: #fff;
  line-height: 25px;
  background: transparent;
  border: none;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 15px 3px 13px;
  border-radius: 12px;
  cursor: pointer;
  transition: 200ms ease-in all;
}
.top-list-item-link:hover {
  background-color: rgba(163, 178, 255, 0.15);
  color: #fff;
}
.top-list-item-arrow {
  margin-left: auto;
}
.top-list-item-link:active {
  background-color: rgba(163, 178, 255, 0.25);
  color: #fff;
}
.top-list-item {
  display: flex;
}
.title-small {
  font-size: 18px;
  list-style: 28px;
  margin-bottom: 5px;
}
.title-orange {
  color: #FDB022;
  font-size: 36px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 0;
}

.aspect-types-chart {
  width: 100%;
  height: 300px; /* Adjust height as needed */
}

.keyword-frequency-chart {
  width: 100%;
  height: 300px; /* Match height with aspect types chart */
}

.chart-card {
  height: 100%;
}

.chart-container {
  width: 100%;
  height: 100%; /* Ensure the chart uses the full height of its container */
}

.scrollable-chart-container {
  max-height: 525px; /* Adjust the height as needed */
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-modal:hover {
    .chart-tooltip {
        visibility: visible;
    }
}

.regions-charts-container {
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .regions-map {
        flex-basis: 40%
    }

    .regions-charts {

    }
}
