.aspect {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, .1);
  border-radius: 14px;
  color: #fff;
  text-align: center;
  overflow: hidden;

  max-width: 170px;
  min-width: 100px;
  height: 150px;

  @media (max-width: 1300px) {
    zoom: 70%
  }

  .aspect-value {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 5px;
  }

  .aspect-value-counter {
    color: #93E396;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.aspect-top-container {
  display: flex;
  flex-direction: column;
  padding: 35px 13px;
  background: rgba(204, 204, 204, .1);
  zoom: 0.6;
  height: 140px;
}
.aspect-icon-container {
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aspect-icon {
  margin: auto;
}

.aspect-name {
  margin: 16px auto 0;
  font-size: 21px;
  line-height: 23px;
  font-weight: 300;
}

.aspect-radar-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.overview-block.aspects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: scroll;

  /* @media (max-width: 1240px) {
    grid-template-columns: repeat(2, 1fr);
  } */

  @media (max-width: 1300px) {
    grid-template-columns: repeat(8, 1fr);
  }
}

.chart-wrapper {
  overflow-x: scroll;
}

.aspects-topbar {
  gap: 20px;
  .applied-filters {
    margin-bottom: 0;
  }
}

.aspect-inner-wrapper {
  display: flex;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
}

.sentiment-region-filters {
    @media (max-width: 500px) {
        display: none;
    }
}

.aspects-section {
    .chart-card-container {
        @media (max-width: 500px) {
            padding: 0;
        }
    }
}

.aspects-filters {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
}

.aspects-filler {
    display: none;

    @media (max-width: 600px) {
        display: block;
        height: 28vh;
    }
}
