.mlondola-faq-page {
    min-height: 100vh;
    font-family: "Arial", sans-serif;
    color: #fff;
    /* background-color: #0a192f; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  
  .container {
    max-width: 1000px;
    padding: 2rem;
  }

  /* 🔎 Search Bar */
  .faq-search {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    background: rgba(255, 255, 255, 0.1);
    padding: 0.8rem;
    border-radius: 5px;
  }
  
  .search-icon {
    color: #f4a261;
    margin-right: 10px;
  }
  
  .faq-search input {
    width: 100%;
    background: transparent;
    border: none;
    color: #fff;
    outline: none;
    font-size: 1rem;
  }
  
  .faq-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #f4a261;
  }
  
  .faq-subtitle {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #ddd;
  }
  
  .faq-category {
    margin-top: 2rem;
  }
  
  .faq-category h2 {
    color: #f4a261;
    margin-bottom: 1rem;
  }
  
  .faq-item {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    cursor: pointer;
  }
  
  .faq-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
  }
  
  .faq-answer {
    color: #ddd;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .faq-contact {
    text-align: center;
    margin-top: 3rem;
  }
  
  .faq-contact h2 {
    color: #f4a261;
  }

  .faq-contact p {
    color: #ddd;
    margin-bottom: 1rem;
  }
  
  .contact-button {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    background-color: #f4a261;
    color: #0a192f;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
  }
  
  .contact-button:hover {
    background-color: #e69540;
  }
  
  /* ⬆️ Back to Top Button */
  .back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #f4a261;
    border: none;
    color: #0a192f;
    padding: 10px 15px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.2rem;
  }
  
  .back-to-top:hover {
    background: #e69540;
  }