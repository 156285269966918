
.footer-container {
    display: flex;
    flex-direction: column-reverse;
    padding: 50px 235px;

    @media (max-width: 480px) {
        padding: 50px 0;
    }

    @media (max-width: 768px) {
        margin-top: 20px;
    }


    .partners-logos {
        flex-wrap: nowrap;
    }

    .footer-logo-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 2px solid white;
        width: 70vw;
        padding-top: 20px;

        @media (max-width: 550px) {
            flex-direction: column-reverse;
            gap: 20px;
        }
    }

    .footer-partners {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

            @media (max-width: 480px) {
            flex-direction: column;
            align-items: center;
            }
    }

    .footer-logo {
        display: flex;
        alignItems: center;

        img {
            width: 150px;
            position: relative;
            bottom: 3px;

            @media (max-width: 768px) {
                width: 100px;
            }
        }
    }

    .quick-links {
        margin: 0;

        @media (max-width: 900px) {
            display: none;
        }
    }

    .footer-links {
        display: flex;
        gap: 20px;
        color: white;

        @media (max-width: 900px) {
            gap: 10px;
        }
    }
}

.footer {
    height: auto;
}
