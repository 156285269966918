.mlondola-privacy-page {
    min-height: 100vh;
    font-family: "Arial", sans-serif;
    color: #fff;
    /* background-color: #0a192f; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  
  .container {
    max-width: 1000px;
    padding: 2rem;
  }
  
  .privacy-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #f4a261;
  }
  
  .privacy-subtitle {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #ddd;
  }
  
  .last-updated {
    font-style: italic;
  }
  
  .privacy-section {
    margin-bottom: 2rem;
  }
  
  .privacy-section h2 {
    color: #f4a261;
    margin-bottom: 0.5rem;
  }
  
  .privacy-section p, .privacy-section ul {
    color: #ddd;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .privacy-section ul {
    padding-left: 20px;
  }
  
  .privacy-section ul li {
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .privacy-title {
      font-size: 2rem;
    }
  
    .privacy-section h2 {
      font-size: 1.3rem;
    }
  
    .privacy-section p, .privacy-section ul {
      font-size: 0.9rem;
    }
  }
  