.App {
  background: url('assets/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
}
.App::after {
  content: '';
  display: block;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  backdrop-filter: blur(22px);
  z-index: 0;
}

.full-height {
  height: 100%;
}
.root_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: end;
  gap: 2px;


  &.authenticated {
    background-color: #091930;
    position: sticky;
    justify-content: space-between;
    z-index: 999;
    margin-left: -150px;
    margin-right: -50px;
    top: 0;
    @media (max-width: 768px) {
      justify-content: space-between;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

.chart_tick_text {
  font-size: 14px;
  font-size: 500;
  color:#fff;
}
