
.top-list-item-rating {
    color:#A3B2FF;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    padding: 3px 0;
    margin-right: 18px;
}
.top-list-item-link {
    width: 100%;
    font-size: 14px;
    color: #fff;
    line-height: 25px;
    background: transparent;
    border: none;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 3px 15px 3px 13px;
    border-radius: 12px;
    cursor: pointer;
    transition: 200ms ease-in all;
  }
  .top-list-item-link:hover {
    background-color: rgba(163, 178, 255, 0.15);
    color:#fff;
  }
  .top-list-item-arrow {
    margin-left: auto;
  }
  .top-list-item-link:active {
    background-color: rgba(163, 178, 255, 0.25);
    color:#fff;
  }
  .top-list-item {
    display: flex;
  }
.title-small {
    font-size: 18px;
    list-style: 28px;
    margin-bottom: 5px;
}
.title-orange {
    color: #FDB022;
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 0;
}

.aspect.categories {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, .1);
  border-radius: 14px;
  color: #fff;
  text-align: center;
  padding-bottom: 5px;
  max-width: 50%;
  flex-shrink: 0;

  .aspect-value {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  .aspect-value-counter {
    color: #93E396;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.aspect-top-container {
  display: flex;
  flex-direction: column;
  padding: 35px 13px;
  background: rgba(204, 204, 204, .1);
  zoom: 0.6
}
.aspect-icon-container {
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aspect-icon {
  margin: auto;
}

.aspect-name {
  margin: 16px auto 0;
  font-size: 21px;
  line-height: 23px;
  font-weight: 300;
}

.overview-header {
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 300;
  font-size: 20px;
  line-height: 22px;
}
.overview-block {
  margin-bottom: 42px;
  display: flex;
  gap: 20px;
  overflow-x: scroll;
}
.category-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.category-selector-title {
  margin: 0 0 0 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}
.category-selector-select .ant-select-selection-item {
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
}
.category-selector-select .ant-select-arrow {
  color: #fff !important;
}

.chart-wrapper {
  overflow-x: scroll;
}

.categories-wrapper {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;

    @media (max-width: 1300px) {
        flex-direction: column;
    }
}

.overview-container {
    width: 25%;

    @media (max-width: 900px) {
        width: 100%;
    }

    .overview-block {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        overflow-y: scroll;

        @media (max-width: 900px) {
            grid-template-columns: repeat(8, 1fr);
        }
    }

    .aspect {
        min-width: 150px;

        .aspect-value{
            padding: 15px;
        }
    }
}

.categories-charts {
    flex-basis: 65%;
}

.categories-subHead {
    font-size: 20px;
    font-weight: normal;
}

.categories-filters{
    .applied-filters {
        margin-bottom: 0;
    }
}
.ant-select-dropdown {
    min-width: 130px;
}

.aspect {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.aspect:hover {
  transform: scale(0.9);
}

.aspect.selected {
  border: 2px solid #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}

.aspect.disabled {
  pointer-events: none;
}
