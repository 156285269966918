.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }
  
  .modal-content {
    background-color: #10214F;
    color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 28rem;
    width: 100%;
    text-align: center;
  }
  
  .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .modal-text {
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  
  .modal-button {
    background-color: #37427A;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    transition: background-color 0.2s ease;
    cursor: pointer;
    border: none;
  }
  
  .modal-button:hover {
    background-color: #303862;
  }
  