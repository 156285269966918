
.home-container {
  max-width: 1200px;
  margin: -70px auto 0;
  padding: 2rem;

  @media (max-width: 768px) {
      padding: 1rem 0;
  }
}

.home-title {
    color:#fff;
    font-size: 64px;
    line-height: 82px;
    font-weight: 200;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span:first-child {
      text-align: left;
      font-weight: 250;
      line-height: 50px;
    }

    @media (max-width: 768px) {
        flex-direction: row;
        align-items: center;
    }
}

.home-title-accent {
    margin-left: 5px;;
    font-weight: 600;
}

.home-navigation {
    display: flex;
    justify-content: flex-start;
    max-width: 800px;
}

.home-navigation-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 110px;
    color: #fff;
    background: rgba(255,255,255,.1);
    border-radius: 18px;
    font-size: 18px;
    line-height: 29px;
    transition: 200ms ease-in all;
    margin: 5px;
}

.home-navigation-link:hover {
    color: #fff;
    background: rgba(255,255,255,0.2);
}
.home-navigation-link:active {
    background: rgba(255,255,255,0.3);
}

.home-navigation-link_icon {
    max-height: 22px;
    margin-bottom: 6px;
}

.home-sponsors {
    display: flex;
    background-color: #10214F;
    opacity: 0.9;

    border-radius: 0.75rem;
    justify-content: space-around;
}

.home-sponsors-image {
    max-height: 150px;
    margin-right: 24px;
}

.home-slide-image {
    min-height: 310px;
    background-size: cover;
    border-radius: 18px;
    margin-bottom: 50px;
}

.home-slide-title {
    font-weight: 300;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 32px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 5px;
}
.home-slide-text {
    font-weight: 300;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
}

button.slick-arrow.slick-prev {
    left: 0;
    top: 380px;
}

button.slick-arrow.slick-next {
    right: 0;
    top: 380px;
}

.ant-carousel .slick-prev::after, .ant-carousel .slick-next::after {
    box-sizing: border-box;
    position: absolute;
    top: calc((16px - 16px / 1.414) / 2);
    inset-inline-start: calc((16px - calc(16px / 1.414)) / 2);
    display: inline-block;
    width: calc(16px / 1.414);
    height: calc(16px / 1.414);
    border: 0 solid #fff;
    border-inline-width: 2px 0;
    border-block-width: 2px 0;
    border-radius: 1px;
    content: "";
}
.ant-carousel .slick-prev::after {
    transform: rotate(-45deg);
}
.ant-carousel .slick-next::after {
    transform: rotate(135deg);
}

.loading-indicator {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #fdb022; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1024px) {
  .home-title {
    text-align: center;
    font-size: 48px;
  }
}

@media (max-width: 768px) {

  .home-navigation {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .home-navigation-link {
    margin: 10px 0;
  }

  .home-sponsors {
    flex-wrap: wrap;
    padding: 0;
    margin: 20px 0;
  }

  .home-sponsors-image {
    margin: 10px;
    max-height: 100px;
  }
}

@media (max-width: 480px) {
  .home-title {
    font-size: 36px;
    flex-direction: column;
  }

  .home-navigation-link {
    width: 90px;
    height: 90px;
    flex-grow: 1;
    font-size: 14px;
  }

}

.home-header {
    flex-wrap: nowrap;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.home-logo {
    display: flex;

    @media (max-width: 768px ) {
        justify-content: center;
    }
    img {
        width: 200px;
        margin-bottom: 20px;

        @media (max-width: 768px ) {
            margin-bottom: 0;
        }
    }
}
