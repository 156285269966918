.establishment-type-toggle {
  margin-bottom:16px;

  @media (max-width: 768px) {
      display: none;
  }
}
.establishment-type-toggle-button {
  margin-right:16px;
}
